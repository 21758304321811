import { Injectable, inject } from '@angular/core';

import { tap } from 'rxjs';

import { LiveArea, LiveAreaGQL, LiveAreasGQL } from '@alan-apps/data-access';
import {
  BaseHttpService,
  BaseService,
  cache,
  ICache,
  useI18nRouter,
} from '@nghedgehog/angular-ui';

import { PrintService } from './print/print.service';

@Injectable({
  providedIn: 'root',
})
export class LiveAreaService implements ICache {
  private _http = inject(BaseHttpService);
  private liveAreaGQL = inject(LiveAreaGQL);
  private liveAreasGQL = inject(LiveAreasGQL);
  private _base = inject(BaseService);
  private _print = inject(PrintService);
  readonly storageKey = 'LiveAreaService';
  private router = useI18nRouter();

  search(query: any) {
    this.router.navigate(['/live-area'], {
      queryParams: query,
    });
  }

  get(id: number) {
    return this._http.apollo(this.liveAreaGQL.fetch({ id }));
  }

  @cache()
  list(option: PageOption, query = {}) {
    return this._http.apollo(this.liveAreasGQL.fetch({ ...option, query }));
  }

  openPrint(id: number, liveArea?: LiveArea, params?: Record<string, string>) {
    return this._base
      .openUrl(this.router.getI18nUrl([`/print/live-area`, `${id}`]), {
        specs: `left=20,top=20,width=1000,height=${
          window.screen.availHeight * 0.75
        }`,
        params: {
          print: 'true',
          ...params,
        },
      })
      .pipe(
        tap((newWindow) => {
          if (liveArea) {
            this._print.setLiveArea(newWindow, liveArea);
          }
        }),
      );
  }
}
