<nav [class]="classList()">
  <ul class="flex flex-wrap">
    @if (noInit()) {
      <li
        class="rounded text-center p-2"
        [ngClass]="
          classification.value === null
            ? 'bg-primary elevation-3 text-white cursor-default'
            : 'cursor-pointer'
        "
        (click)="setValue({ id: null })"
      >
        <h5 class="m-0">
          {{ '全部' | translate }}
        </h5>
      </li>
    }
    @for (type of types; track type; let i = $index) {
      <li
        class="rounded text-center p-2"
        [ngClass]="
          type.id === classification.value
            ? 'bg-primary elevation-3 text-white cursor-default'
            : 'cursor-pointer'
        "
        (click)="setValue(type)"
      >
        <h5 class="m-0">
          {{ type.title | translate }}
        </h5>
      </li>
    }
  </ul>
</nav>
